import { useClient } from '@yema/commerce/APIClient';

export const useFragment = (id, fragment, fragmentName, skip = false) => {
    const client = useClient();
    if (!id || !fragment) return null;
    return skip
        ? null
        : client.readFragment({
              id,
              fragment,
              fragmentName,
          });
};
