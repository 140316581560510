import React, { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { InstantSearch, connectAutoComplete, Configure } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { useMedia } from 'use-media';
import { get_, dataTransformers } from 'sf-modules';
import { useStateEngine } from '@yema/commerce';
import { APP_STATE_TYPES, MODAL_KIND } from '@yema/commerce/CommerceProvider';
import useTracker from '@yema/commerce/hooks/tracking/useTracker';
import { CheckoutBasicFieldsFragment } from '@yema/commerce/graphql/fragments/checkout/CheckoutBasicFields.gql';
import { useFragment } from 'hooks/useFragment';
import Header from '@/yema-designkit/webkit/organisms/Header';

const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

const HeaderWithSearch = ({
    checkoutBase64Id,
    withSearch,
    fullHeader,
    userLoggedIn,
    username,
    submenu,
    mobileMenu,
    currentCategory,
    hits,
    refine,
    currentRefinement,
    userConfigPage,
    promoHeader,
    handleClosePromoHeader,
    ...otherProps
}) => {
    const router = useRouter();
    const { trackEvent } = useTracker();

    const checkoutBasicInfos = useFragment(
        `Checkout:${checkoutBase64Id}`,
        CheckoutBasicFieldsFragment,
        'CheckoutBasicFields'
    );

    const isMobile = useMedia({ maxWidth: 767 });
    const [{ catalog, config }, dispatch] = useStateEngine();
    const [sortedHits, setSortedHits] = useState({});

    const doRefine = useCallback((value) => {
        if (value === '') setSortedHits({});
        else {
            refine(value);
            trackEvent({
                type: 'Product Searched',
                data: {
                    category: 'Catalog',
                    label: value,
                },
            });
        }
    }, []);

    const handleClickOnProduct = (product) => {
        trackEvent({
            type: 'Product Clicked',
            data: { rawProduct: product },
        });
    };

    useEffect(() => {
        if (hits && currentRefinement && currentRefinement !== '') {
            const parsedSearchResults = [];
            hits.forEach((hit) => {
                const mainAisle = get_(hit, ['aisles', 'lvl0', 0]);

                if (!mainAisle) mainAisle = 'Otras categorías';

                if (parsedSearchResults[mainAisle] === undefined)
                    parsedSearchResults[mainAisle] = [];

                let parsedHit = dataTransformers.transformProductFromAlgolia(hit);
                parsedSearchResults[mainAisle].push(parsedHit);
            });

            // Sort results by category name
            const sortedResults = {};
            Object.keys(parsedSearchResults)
                .sort()
                .forEach((sortedKey) => {
                    sortedResults[sortedKey] = parsedSearchResults[sortedKey];
                });
            setSortedHits(sortedResults);
        }
    }, [hits]);

    return (
        <Header
            fullHeader={fullHeader}
            userLoggedIn={userLoggedIn}
            username={username}
            submenu={submenu}
            mobileMenu={mobileMenu}
            cartCount={checkoutBasicInfos ? checkoutBasicInfos.quantity : 0}
            // cartTotal={checkoutBasicInfos ? checkoutBasicInfos.totalPrice : null}
            refineResults={doRefine}
            currentCategory={currentCategory}
            searchResults={sortedHits}
            onClickSearch={(path) => router.push(path)}
            isMobile={isMobile}
            catalogSettings={catalog}
            onChangeDeliveryLocation={() =>
                dispatch({
                    type: APP_STATE_TYPES.TOGGLE_MODAL,
                    kind: MODAL_KIND.CHANGE_DELIVERY_LOCATION,
                    open: true,
                })
            }
            promoHeader={promoHeader}
            promoHeaderIsVisible={config.promoHeader.visible}
            onClosePromoHeader={handleClosePromoHeader}
            userConfigPage={userConfigPage}
            onClickOnProduct={handleClickOnProduct}
            {...otherProps}
        />
    );
};

const CustomAutocomplete = connectAutoComplete(HeaderWithSearch);

const YDSHeader = ({ ...otherProps }) => {
    const [{ catalog }] = useStateEngine();

    if (!catalog || !catalog.index) return <HeaderWithSearch withSearch={false} {...otherProps} />;

    return (
        <InstantSearch searchClient={searchClient} indexName={catalog.index}>
            <Configure hitsPerPage={12} />
            <CustomAutocomplete withSearch={true} {...otherProps} />
        </InstantSearch>
    );
};

export default React.memo(YDSHeader);
